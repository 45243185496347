.dotNetWidth {
  width: 85%;
}

.javaWidth {
  width: 70%;
}

.javascriptWidth {
  width: 60%;
}

.xamlWidth {
  width: 50%;
}

.sectionHeight {
  height: 24px;
}
