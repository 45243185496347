.scroll-top {
  /* Scroll icon is in the bottom right corner */
  position: fixed;
  bottom: 20px;
  right: 20px;

  z-index: 1000;

  color: #33ba9e;
  background-color: white;
  opacity: 0.9;

  text-align: center;
  transition: all 0.5s linear;

  border-radius: 50%;
  width: 50px;
  line-height: 50px;
}

.scroll-top i {
  vertical-align: middle;
  cursor: pointer;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}
