.appContainerProps {
  max-width: 1400px;
}

.noPadding {
  padding-top: 0 !important;
}

.backgroundFiller {
  background-color: white !important;
}
