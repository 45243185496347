.ninetyWidth {
  width: 90%;
}

.eightyWidth {
  width: 85%;
}

.seventyFiveWidth {
  width: 75%;
}

.seventyWidth {
  width: 95%;
}

.smallText {
  font-size: 9.5pt !important;
}
