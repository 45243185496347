.socialSpacing {
  margin-right: 10px !important;
}

.socialSize {
  font-size: 15pt !important;
  cursor: pointer;
}

.languagePos {
  text-align: left !important;
  cursor: pointer;
}

.footerPos {
  margin-top: -75px !important;
}

.linkProps {
  text-decoration: none !important;
}

/* Portrait */
@media only screen and (min-device-width: 360px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .footerPos {
    margin-top: 10px !important;
  }
}
